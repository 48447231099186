<template>
	<!--	INVITACION MENU-->
	<div class="container p-0 mt-3">
		<div class="card">
			<div class="card-header" v-if="creatorUser">
				<h3 v-if="!isWalking">{{ creatorUser.name }} te ha compartido la carta</h3>
				<h3 v-else>Accede a nuestra carta</h3>
			</div>
			<div class="row g-0 justify-content-center">
				<div class="col-md-8">
					<div class="card-body h-100 align-content-center">
						<div v-if="isValidReservation/* && !isPaxLimit*/">
							<p class="text-center">¿Cómo quieres que te llamemos?</p>
							<!--								&& !isPaxLimit-->
							<div v-if="isValidReservation" class="input-group mb-3">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
								<input type="text" class="form-control" placeholder="Introduce tu nombre (sin apellidos):" v-model="signupName" @focus="deselectCheckboxes">
							</div>
						</div>

						<div v-if="invitedUsers.length" class="mb-3">
							<div class="text-start" v-if="isValidReservation">
								¿Estás en la lista? Selecciona tu nombre.
							</div>

							<div v-if="!isWalking" class="col-md-6 col-lg-auto text-start">
								<div v-if="creatorUser" :key="creatorUser.uid" class="form-check">
									<input disabled v-if="isValidReservation" v-model="selectedInvitedUser" :id="creatorUser.uid" :value="creatorUser.uid" class="form-check-input" type="radio">
									<label class="form-check-label" :for="creatorUser.uid">
										{{ creatorUser.name }}
									</label>
								</div>
							</div>

							<div v-for="invitedUser in invitedUsers" :key="invitedUser.uid" class="col-md-6 col-lg-auto text-start">
								<div class="form-check">
									<input v-if="isValidReservation" v-model="selectedInvitedUser" :id="invitedUser.uid" :value="invitedUser.uid" :disabled="invitedUser.typeHost === 1" class="form-check-input" type="radio">
									<label class="form-check-label" :for="invitedUser.uid">
										{{ invitedUser.name }}
									</label>
								</div>
							</div>
						</div>

						<div class="row-center" v-if="isValidReservation">
							<button type="button" class="btn btn-primary w-100" @click="submitAcceptInvitationMenu()" :disabled="(!signupName && (selectedInvitedUser === 1 || !selectedInvitedUser))">
								{{acceptButtonText}}
							</button>
						</div>

						<div id="reservationResponseCollapse" v-if="showErrorMessageEmail" class="col-12">
							<div class="alert alert-danger" role="alert">
								¿Cómo podemos llamarte?
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="showAlertRedirectMenu" class="modal fade show" tabindex="-1" aria-labelledby="modalUserFoundByTokenLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered ">
			<div class="modal-content border-secondary border-1">
				<div class="modal-body text-center">
					<p class="fs-3">
						Estamos preparando tu carta personalizada, un momento…
					</p>
					<SpinnerIcon class="fw-bold fs-3"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {dateFormatterService} from "@/service/dateFormatterService";
import {hideAllModals, showModal} from "@/utils";
import SpinnerIcon from "@/components/core/icons/SpinnerIcon";

export default {
	name: "InvitationMenu",
	components: {SpinnerIcon},
	inject: {
		userRepository: 'userRepository',
		manageToken: 'manageToken',
		reservationRepository: 'reservationRepository',
	},
	data(){
		return{
			selectedInvitedUser: null,
			signupName: null,
			showErrorMessageEmail: false,
			hostCode: '',

			textStandard: 'Confirmar',
			textLoading:'Confirmando...',
			isLoadingBtn: false,
		}
	},
	computed:{
		...mapState('user', ['user']),
		...mapGetters('invitation', ['creatorUser','reservationInfo', 'isWalking', 'invitedUsers', 'isValidReservation']),

		acceptButtonText() {
			return this.isLoadingBtn ? this.textLoading : this.textStandard;
		}
	},
	watch:{
		selectedInvitedUser(newVal) {
			if (newVal && this.signupName) {
				this.signupName = '';
			}
		}
	},
	beforeUnmount() {
		this.date = dateFormatterService.formattedDate(this.reservationInfo.startDateTime);
		this.time = dateFormatterService.formattedTime(this.reservationInfo.startDateTime);

		console.log({reservationINfo:this.reservationInfo})
		console.log({user:this.user})

	},
	mounted() {
		this.checkUserAndRedirect()
	},
	methods: {
		async checkUserAndRedirect(){
			if (this.user) {
				showModal('showAlertRedirectMenu')
				const isMemberOfReservation = this.reservationInfo.reservationUsers.find(user => user.userId === this.user.id);
				if(isMemberOfReservation){
					await this.reservationRepository.enableReservation({reservationWid:this.reservationInfo.id});
					/*setTimeout(() => {
						window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.user.hostCode}`;
					}, 4000);*/

				}else{
					await this.reservationRepository.addInvitedUser(this.reservationInfo.id, this.user.id, 1);
					/*setTimeout(() => {
						window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.user.hostCode}`;
					}, 2000);*/
				}
				this.redirectToMenu(this.reservationInfo.id, this.user.hostCode);
			}
		},
		async submitAcceptInvitationMenu() {
			this.isLoadingBtn = true;
			if (this.selectedInvitedUser === 1 || !this.selectedInvitedUser) {
				//TODO: Si agrega el nombre
				this.isLoadingBtn = true;

				if (!this.signupName) {
					alert('El nombre debe tener un valor');
					this.isLoadingBtn = false;
					return;
				}

				const data = {
					invitedUserName: this.signupName,
					hostCode: this.hostCode,
					reservationWid: this.reservationInfo.id
				};
				const response = await this.userRepository.createGuestUserMenu(data);
				if (response.result === 0) {

					this.isLoadingBtn = false;

					const invitedUser = response.invitedUser;
					invitedUser.creationDate = new Date();
					this.manageToken.setToken(response.token);
					console.log("vamosss",this.reservationInfo, this.reservationInfo.id, invitedUser.hostCode)
					this.redirectToMenu(this.reservationInfo.id, invitedUser.hostCode);

				} else {
					hideAllModals();
				}
				this.isLoadingBtn = false;

			} else {
				//TODO: selecciona el checkbox
				this.isLoadingBtn = false;
				window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.selectedInvitedUser}`;
			}
		},

		redirectToMenu(reservationWid, uid){

			showModal('showAlertRedirectMenu')
			setTimeout(() => {
				window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${reservationWid}&uid=${uid}`;
				// hideAllModals()
			}, 4000);
		},

		deselectCheckboxes() {
			this.selectedInvitedUser = null;
		}
	}
}
</script>

<style scoped>

</style>
